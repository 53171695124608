import React, { useEffect, useState } from 'react';
import { graphql, Link, navigate } from 'gatsby';
import { getUser } from '../services/auth';
import { isLoggedIn } from '../services/auth';
import axios from 'axios';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Row from '../components/row/row';
import RenderBlock from '../components/renderBlock/renderBlock';
import { FaFilePdf } from 'react-icons/fa';
import moonImage from '../assets/images/moon.png';
import Checkmark from '../assets/images/checkmark.inline.svg';
import cn from 'classnames';
import { GetClasses } from '../hooks/classes';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { devices } from '../utils';
import styled from 'styled-components';
import confetti from 'canvas-confetti';
import coaching from '../assets/images/signs/coaching.png';
import revival from '../assets/images/signs/revival.png';
import royals from '../assets/images/signs/royals.png';
import stairway from '../assets/images/signs/stairway-to-heaven.png';
import stardust from '../assets/images/signs/stardust.png';
import success from '../assets/images/signs/success.png';
import death from '../assets/images/signs/til-death-do-us-party.png';
import wolf from '../assets/images/signs/wolf-pack.png';
import { Spinner } from '../components/spinner';

export const query = graphql`
  query ($id: String!) {
    strapiClass(id: { eq: $id }) {
      id
      strapiId
      title
      slug
      content
      pdf {
        url
      }
      hero {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      award {
        alternativeText
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      objective
      tasks {
        id
        todo
        order
        link {
          id
          link
          text
        }
      }
      claim {
        id
        link
        text
      }
      completed_message
      order
    }
  }
`;

const H1 = styled.h1`
  margin: 0 0 40px;
`;

const HeroImg = styled(GatsbyImage)`
  display: block;
  max-height: 500px;
  object-fit: cover;
  width: 100%;
`;

const AwardP = styled.p`
  font-weight: 700;
`;

const AwardContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 2px 5px 1px rgb(0 0 0 / 25%);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
`;

const ObjectiveDiv = styled.div`
  background-color: var(--c-silver-sand);
  padding: 20px 0;
`;

const ObjectiveRow = styled(Row)`
  display: grid;
  gap: 40px;

  @media ${devices.tablet} {
    grid-template-columns: 1fr 400px;
  }
`;

const ObjectiveP = styled.p`
  font-size: 1.2rem;

  @media ${devices.tablet} {
    font-size: 1.6rem;
  }
`;

const Tasks = styled.div`
  background-color: var(--c-theme-white);
  padding: 30px 0;
`;

const Tools = styled.div`
  background-color: var(--c-platinum);
  padding: 30px 0;
`;

const PDFLink = styled.a`
  align-items: center;
  display: inline-flex;
  margin-top: 20px;
  width: auto;
`;

const PDFIcon = styled(FaFilePdf)`
  margin-right: 5px;
`;

const MoonImg = styled.img`
  position: relative;
  z-index: 1;
`;

const CompleteContainer = styled.span`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;

const CompleteButton = styled.button`
  align-items: center;
  background-color: transparent;
  border: 0;
  display: flex;
  flex-direction: column;
  position: relative;
  order: 1;

  @media ${devices.tablet} {
    order: 0;
  }

  &:focus,
  &:hover,
  &.is-complete {
    path {
      fill: var(--c-primary);
    }
  }

  svg {
    max-width: 150px;
    position: absolute;
    z-index: 2;
  }
`;

const CompleteSection = styled.div`
  background-color: var(--c-secondary);
  display: grid;
  gap: 40px;
  border-radius: 10px;
  padding: 20px;
  align-items: center;

  @media ${devices.tablet} {
    grid-template-columns: 300px 1fr;
  }
`;

const CompleteP = styled.p`
  margin-bottom: 10px;
  font-size: 1.2rem;

  @media ${devices.tablet} {
    font-size: 1.6rem;
  }
`;

export default function Class({ data }) {
  const course = data.strapiClass;
  const [userTasks, setUserTasks] = useState([]);
  const [userClasses, setUserClasses] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/app/login');
    }

    getAllUserTasks();
  }, []);

  const confettiFireworks = () => {
    const duration = 3 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 3 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(1.1, 1.3), y: Math.random() - 0.6 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.3, 0.8), y: Math.random() - 0.1 },
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        })
      );
    }, 250);
  };

  const getAllUserTasks = async () => {
    return await axios
      .get(`${process.env.GATSBY_ADMIN_URL}/users/${getUser().userId}`)
      .then((response) => {
        if (response.data.completed_tasks !== userTasks) {
          setUserTasks(response.data.completed_tasks);
        }
        if (response.data.completed_classes !== userClasses) {
          setUserClasses(response.data.completed_classes);
        }
      });
  };

  const handleComplete = async (event) => {
    event.preventDefault();

    confettiFireworks();

    if (isClassCompleted()) {
      return;
    }

    updateUserCompletedCourses([...userClasses, { id: course.strapiId }]);
  };

  const updateUserCompletedCourses = (newClasses) => {
    setUserClasses(newClasses);
    axios
      .put(
        `${process.env.GATSBY_ADMIN_URL}/users/${getUser().userId}`,
        {
          completed_classes: newClasses,
        },
        {
          headers: {
            Authorization: `Bearer ${getUser().jwt}`,
          },
        }
      )
      .then(() => {
        getAllUserTasks();
      })
      .catch((error) => console.log(error));
  };

  const updateUserCompletedTasks = (newTasks) => {
    setUserTasks(newTasks);
    setLoading(true);
    axios
      .put(
        `${process.env.GATSBY_ADMIN_URL}/users/${getUser().userId}`,
        {
          completed_tasks: newTasks,
        },
        {
          headers: {
            Authorization: `Bearer ${getUser().jwt}`,
          },
        }
      )
      .then(() => {
        setLoading(false);
        getAllUserTasks();
      })
      .catch((error) => console.log(error));
  };

  const handleTask = async (event) => {
    event.preventDefault();
    const taskId = event.target.id;
    const checked = event.target.checked;

    // Unchecking a checkbox
    if (!checked) {
      const updatedTasks = [...userTasks];
      const index = updatedTasks.findIndex((item) => item.id === Number(taskId));
      if (index !== -1) {
        // Remove unchecked task
        updatedTasks.splice(index, 1);
        // Update state
        // Update database
        updateUserCompletedTasks(updatedTasks);
      }
    } else {
      updateUserCompletedTasks([...userTasks, { id: taskId }]);
    }
  };

  const isClassCompleted = () => {
    return userClasses.some((item) => item.id === course.strapiId);
  };

  const allTasksComplete = () => {
    const classTasks = GetClasses().filter((item) => item.strapiId === course.strapiId)[0].tasks
      .length;
    const userCompletedTasks = userTasks.filter((item) => item.class === course.strapiId).length;

    return classTasks === userCompletedTasks;
  };

  const heroImage = getImage(course.hero?.localFile);
  const awardImage = getImage(course.award?.localFile);
  const nextClass = GetClasses().filter((item) => item.order === course.order + 1);
  const signs = [death, stardust, revival, stairway, wolf, royals, coaching, success];

  return (
    <Layout>
      <SEO title={course.title} description="" />
      {course.hero && <HeroImg image={heroImage} alt={course.hero.alternativeText} />}

      <ObjectiveDiv>
        <Row>
          <H1 dangerouslySetInnerHTML={{ __html: course.title }} />
          <h2>Objective</h2>
        </Row>
        <ObjectiveRow>
          <div>
            <ObjectiveP>{course?.objective}</ObjectiveP>
          </div>

          <div>
            {course.award && (
              <div>
                <AwardContainer>
                  <HeroImg image={awardImage} alt={course.award.alternativeText} />
                </AwardContainer>
                <AwardP>Earn this pin after completing all the tasks!</AwardP>
              </div>
            )}
          </div>
        </ObjectiveRow>
      </ObjectiveDiv>

      <Tasks>
        <Row>
          <h2>Tasks</h2>
          {course.tasks && (
            <form>
              <ul>
                {course.tasks
                  .sort((a, b) => a.order - b.order)
                  .map((task) => {
                    const isChecked = userTasks.some((item) => item.id === task.id);
                    return (
                      <li key={task.id}>
                        <input
                          type="checkbox"
                          id={task.id}
                          name="task"
                          checked={isChecked}
                          onChange={handleTask}
                          className="h-visual-hide input-custom-checkbox"
                        />

                        <label htmlFor={task.id} className="label-custom-checkbox">
                          {task.todo}
                        </label>
                        {task.link.length > 0 && (
                          <div className="wysiwyg-content">
                            <ul style={{ marginLeft: '30px', marginBottom: '20px' }}>
                              {task.link.map((item) => (
                                <li key={item.id}>
                                  <a href={item.link} target="_blank" rel="noreferrer">
                                    {item?.text ? item.text : item.link}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </li>
                    );
                  })}
              </ul>
            </form>
          )}

          {allTasksComplete() && (
            <CompleteSection>
              <CompleteButton
                onClick={handleComplete}
                className={cn({ 'is-complete': isClassCompleted() })}
              >
                <CompleteContainer>
                  <MoonImg src={moonImage} alt="Complete Class" />
                  <Checkmark />
                </CompleteContainer>
                {isClassCompleted() ? 'Completed!' : 'Complete Class'}
              </CompleteButton>
              <div>
                <CompleteP>{course?.completed_message}</CompleteP>
                {isClassCompleted() && course?.claim?.length > 0 && (
                  <ul style={{ display: 'flex', gap: '20px', flexWrap: 'wrap' }}>
                    {course.claim.map(({ id, link, text }) => (
                      <li key={id}>
                        <a href={link} className="button" target="_blank" rel="noreferrer">
                          {text ? text : link}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
                {nextClass[0] ? (
                  <div style={{ marginTop: '20px' }}>
                    <p>On to the next class!</p>
                    <Link to={`/class/${nextClass[0].slug}`}>
                      <img
                        src={signs[course.order - 1]}
                        alt={nextClass[0].title}
                        style={{ width: '150px' }}
                      />
                    </Link>
                  </div>
                ) : (
                  <div style={{ marginTop: '20px' }}>
                    <p>You completed all the classes!</p>
                    <Link to="/success">
                      <img src={signs[signs.length - 1]} alt="Success" style={{ width: '150px' }} />
                    </Link>
                  </div>
                )}
              </div>
            </CompleteSection>
          )}
        </Row>
      </Tasks>

      <Tools>
        <Row>
          <h2>Helpful Tools &amp; Videos</h2>
          <div className="wysiwyg-content">
            {course.content.map((block) => (
              <RenderBlock key={block.id} block={block} />
            ))}
            {course.pdf?.url && (
              <PDFLink
                href={course.pdf.url}
                className="button button--hollow h-spacing-bottom"
                target="_blank"
                rel="noopener noreferrer"
              >
                <PDFIcon /> Class PDF
              </PDFLink>
            )}
          </div>
        </Row>
      </Tools>

      <Spinner loading={loading} />
    </Layout>
  );
}
