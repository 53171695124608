import { useStaticQuery, graphql } from 'gatsby';

export const GetClasses = () => {
  return useStaticQuery(
    graphql`
      query {
        allStrapiClass {
          nodes {
            id
            strapiId
            slug
            title
            order
            tasks {
              id
              todo
              class
            }
            card {
              alternativeText
              url
            }
            award {
              alternativeText
              url
            }
          }
        }
      }
    `
  ).allStrapiClass.nodes;
};
